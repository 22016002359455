<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand ps-l-28">
            <a class="navbar-item" href="https://bulma.io">
                                              <img src="../assets/logo.png" width="180" height="80">
                                            </a>
    
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="active">
                                              <span aria-hidden="true"></span>
                                              <span aria-hidden="true"></span>
                                              <span aria-hidden="true"></span>
                                            </a>
        </div>
        <div id="navbarBasicExample" class="navbar-menu"  :class="isActive ? 'is-active' : ''">
            <div class="navbar-end ps-r-28">
                <div class="navbar-item">
                    <div class="buttons">
                        <b-button v-if="!this.$parent.account" type="is-primary" v-on:click="click('switchNetwork')">Connect to a Wallet</b-button>
                        <b-navbar-dropdown v-else :label="this.$parent.balance.toString()">
                            <b-navbar-item v-on:click="click('disconnect')">
                                Logout
                            </b-navbar-item>
                        </b-navbar-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<style>
.ps-l-28 {
    padding-left: 28px;
}

.ps-r-28 {
    padding-right: 28px;
}
</style>

<script>
export default {
    components: {},
    data() {
        return {
            isActive:false,
        };
    },
    methods: {
        click: function(event) {
            this.$emit('watchChild', event); //触发$emit绑定的switchNetwork方法
        },
        active: function() {
            this.isActive = !this.isActive;
        }
    }
}
</script>