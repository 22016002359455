<template>
    <div class="home">
        <Nav @watchChild="onNavClick"></Nav>
        <!-- <img src="../assets/logo.svg" width="300" /> -->
        <div class="container">
            <div class="content-container is-flex columns is-mobile">
                <div class="description card-bg column">
                    <section class="hero">
                        <div class="hero-body has-text-left">
                            <p class="title">
                                Only $0.30 per Goerli ETH!
                            </p>
                            <p class="subtitle">
                                Get test coins quickly and cheaply to help get your business off the ground.
                            </p>
                            <div class="help">
                                <div>
                                    <b-tooltip label="You need to buy some Polygon USDT on the CoinBase, then select the amount to buy on the right and click to pay." multilined>
                                        <div class="is-size-6">How do I buy test coins?</div>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
    
                <div class="buy card-bg column">
                    <div class="b-card content-width">
                        <div class="block is-size-6 has-text-left has-text-weight-semibold">amount</div>
                        <div class="block">
                            <div class=" field has-addons has-addons-centered">
                                <div class="control is-expanded">
                                    <b-input type="number" value="0.00" maxlength="10" @input="calculationAmount">
                                    </b-input>
                                </div>
                                <div class="control">
                                    <span class="select">
                                                                                                                                        <select>
                                                                                                                                            <option>Goerli</option>
                                                                                                                                        </select>
                                                                                                                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="block is-size-6 has-text-left has-text-weight-semibold">cost</div>
                        <div class="block cost-number">
                            <div class="chain-details">
                                <img data-v-fd81840a="" src="../assets/polygon-chain.svg" alt="polygon chain logo" height="24" width="24">
                                <div class="font-label-small">Polygon chain usdt</div>
                            </div>
                            <div>$ {{cost}}</div>
                        </div>
                    </div>
                    <div class="block">
                        <b-button type="is-primary" class="button content-width" @click="pay">Pay(%70 off)</b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
</template>

<style>
.content-container {
    padding-top: 30px;
    margin: 30px auto;
}

.subtitle {
    margin-top: 50px;
}

.card-bg {
    background: hsla(0, 0%, 100%, .24);
    border-radius: 10px;
    width: 100%;
}

.description {
    max-height: 500px;
}

.buy {
    max-height: 500px;
    width: 700px;
    margin-left: 50px;
}

.content-width {
    max-width: 350px;
    margin: 10px auto;
}

.b-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
}

body {
    background: radial-gradient(66.32% 66.32% at 54.13% 113.95%, rgba(108, 38, 255, .2) 0, rgba(242, 89, 255, 0) 100%), linear-gradient(211.99deg, rgba(65, 157, 241, .2) -4.17%, rgba(45, 143, 234, 0) 68.7%), radial-gradient(100% 100% at 28.65% 0, #d5bdf5 0, rgba(250, 247, 254, 0) 100%);
}

.cost-number {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f8;
    border-radius: 12px;
}

.chain-details {
    display: flex;
    align-items: center;
}
</style>

<script>
import Web3 from "web3";
import Nav from "./Nav.vue";
import Abi from "../../contract/abi/erc20.json";
import axios from 'axios'
const USDTAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
const USDTDecimal = 6;
const Url = "https://api.buytestcoin.com"
const Api = { createOrder: "/buytestcoin/create_order", settlement: "/buytestcoin/settlement" }
export default {
    components: {
        Nav
    },
    data() {
        return {
            selected_network: "",
            account: "",
            web3: "",
            balance: 0,
            cost: 0,
            isLoading: false
        };
    },
    methods: {
        onNavClick(event) {
            switch (event) {
                case "switchNetwork":
                    this.switchNetwork('polygon')
                    break;
                case "disconnect":
                    this.disconnect();
                    break;
            }
        },
        calculationAmount(amount) {
            this.cost = Math.round(amount * 0.3 * 100) / 100
        },
        async pay() {
            if (this.account == "") {
                this.connect();
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `Tap the upper right corner to connect the wallet and try again`,
                    position: 'is-top',
                    type: 'is-warning',
                })
                return
            }
            if (this.cost <= 0) {
                return
            }
            if (this.cost > this.balance) {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `Your balance is not enough`,
                    position: 'is-top',
                    type: 'is-danger'
                })
                return
            }
            this.isLoading = true;
            const res = await axios.post(Url + Api.createOrder, { buyer_address: this.account, cost: this.cost })
            if (res.data.code == 200) {
                this.transfer(this.cost, res.data.data.payment_address)
            } else {
                this.isLoading = false;
                this.$buefy.toast.open({
                    duration: 5000,
                    message: res.data.msg,
                    position: 'is-top',
                    type: 'is-danger'
                })
            }
        },
        async connect() {
            const app = this;
            if (window.ethereum) {
                // Check if network is desired one
                app.selected_network = await app.web3.eth.net.getId();
                // Request accounts
                await window.ethereum.send("eth_requestAccounts");
                // Read accounts
                const accounts = await app.web3.eth.getAccounts();
                if (accounts[0] !== undefined) {
                    app.account = accounts[0];
                    // Take balance
                    // const balance = await app.web3.eth.getBalance(accounts[0]);
                    // app.balance = parseFloat(
                    //     app.web3.utils.fromWei(balance, "ether")
                    // ).toFixed(10);
                    app.getTokenBalance(accounts[0])
                    localStorage.setItem("connected", app.account);
                }
            } else {
                app.$buefy.toast.open({
                    duration: 5000,
                    message: "Please install Metamask",
                    position: 'is-top',
                    type: 'is-danger'
                })
            }

        },
        async disconnect() {
            const app = this;
            localStorage.removeItem("connected");
            app.account = "";
            app.balance = 0;
            location.reload();
        },
        async switchNetwork(what) {
            const app = this;
            if (window.ethereum) {
            if (what === "polygon") {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [{
                        chainId: "0x89",
                        chainName: "Polygon",
                        rpcUrls: ["https://rpc-mainnet.matic.network"],
                        nativeCurrency: {
                            name: "Polygon Mainnet",
                            symbol: "MATIC",
                            decimals: 18,
                        },
                        blockExplorerUrls: ["https://polygonscan.com/"],
                    }, ],
                });
            } else if (what === "mumbai") {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [{
                        chainId: "0x13881",
                        chainName: "Mumbai",
                        rpcUrls: ["https://rpc-mumbai.matic.today"],
                        nativeCurrency: {
                            name: "MATIC",
                            symbol: "MATIC",
                            decimals: 18,
                        },
                        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                    }, ],
                });
            } else if (what === "goerli") {
                console.log("-----")
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{
                        chainId: "0x5",
                    }, ],
                });
            }
            app.connect();
            } else {
                app.$buefy.toast.open({
                    duration: 5000,
                    message: "Please install Metamask",
                    position: 'is-top',
                    type: 'is-danger'
                })
            }
        },
        async transfer(cost, toAddress) {
            const app = this;
            if (toAddress == "") {
                return
            }
            if (app.web3) {
                let ethContract = new app.web3.eth.Contract(Abi.abi, USDTAddress) //所有代币的abi可以通用（abi,合约地址）
                let amount = cost * Math.pow(10, USDTDecimal);
                let fromAddress = await app.web3.eth.getAccounts()
                ethContract.methods.transfer(toAddress, amount + "").send({ from: fromAddress[0] }).then(function(result) {
                    app.isLoading = false;
                    console.log(result);
                    if (result.status) {
                        app.Settlement(toAddress)
                    }
                }).catch(function(error) {
                    app.isLoading = false;
                    if (error.code == 4001) {
                        app.$buefy.dialog.alert({
                            title: 'Failure',
                            message: "Sorry, You rejected the deal.",
                            confirmText: 'Ok!'
                        })
                    }
                });
            }

        },
        async Settlement(toAddress) {
            const app = this;
            app.isLoading = true;
            var request = axios.post(Url + Api.settlement, { payment_address: toAddress })
            request.then(function(res) {
                app.isLoading = false;
                if (res.data.code == 200) {
                    app.$buefy.dialog.alert({
                        title: 'Success',
                        message: 'Goerli will soon be in your wallet.',
                        confirmText: 'Cool!'
                    })
                } else {
                    app.$buefy.dialog.alert({
                        title: 'Failure',
                        message: "Sorry, There's been a glitch in the system. Don't worry, try again or contact me (Telegram :buytestcoin)",
                        cancelText: 'Cancel',
                        confirmText: 'Try again',
                        onConfirm: () => app.Settlement(toAddress)
                    })
                }
            })
            request.catch(function(error) {
                console.log(error);
                app.$buefy.dialog.alert({
                    title: 'Failure',
                    message: "Sorry, There's been a glitch in the system. Don't worry, try again or contact me (Telegram :buytestcoin)",
                    cancelText: 'Cancel',
                    confirmText: 'Try again',
                    onConfirm: () => app.Settlement(toAddress)
                })
            });
        },
        //查询代币余额
        async getTokenBalance(fromAddress) {
            const app = this;
            if (app.web3) {
                var web3 = new Web3(window.web3.currentProvider);
                let ethContract = new web3.eth.Contract(Abi.abi, USDTAddress) //所有代币的abi可以通用（abi,合约地址）
                let balance = await ethContract.methods.balanceOf(fromAddress).call()
                app.balance = parseFloat(
                    balance / Math.pow(10, USDTDecimal)
                ).toFixed(2);
            }
        },
    },
    async mounted() {
        const app = this;
        if (window.ethereum) {
            app.web3 = await new Web3(window.ethereum);
            const accounts = await app.web3.eth.getAccounts();
            if (accounts.length > 0) {
                if (accounts[0] === localStorage.getItem("connected")) {
                    //const balance = await app.web3.eth.getBalance(accounts[0]);
                    app.account = accounts[0];
                    // app.balance = parseFloat(
                    //     app.web3.utils.fromWei(balance, "ether")
                    // ).toFixed(10);
                    app.getTokenBalance(accounts[0])
                    localStorage.setItem("connected", app.account);
                }
            }
        }
    },
};
</script>
